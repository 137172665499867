// react
import { useCallback } from 'react';

// hooks
import { useHistory } from 'react-router-dom';

export default function useExcludedRoute() {
  // hooks
  const history = useHistory();

  const excludedRoute = useCallback(() => {
    // constants
    const excludedRoutes = ['/'];
    const conditionallyExcludedRoutes = [
      '/payment',
      '/payment-failure',
      '/payment-success',
    ];

    // conditionally exclude these routes based on whether or not the app is using the EPX payment flow
    if (process.env.REACT_APP_SHOULD_USE_EPX === 'true')
      conditionallyExcludedRoutes.forEach((route) =>
        excludedRoutes.push(route)
      );

    if (!excludedRoutes.includes(history.location.pathname)) return false;

    return true;
  }, [history.location.pathname]);

  return excludedRoute();
}
