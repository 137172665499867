import React, { useEffect, VFC } from 'react';

export const GoogleTranslator: VFC = function () {
  useEffect(() => {
    const googleScript = document.getElementById('google-translate-init');

    if (!googleScript) {
      const script = document.createElement('script');
      script.id = 'google-translate-init';

      script.src =
        'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;

      document.body.appendChild(script);
    }
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      id="google_translate_element"
    />
  );
};
