// react
import React, { VFC } from 'react';

// packages
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// hooks
import { useAppSelector } from 'store/hooks';

// redux
import { selectLinks } from 'store/modules/config/selectors';

// components
import Footer from 'components/ThankYouPage/Footer';
import HeaderDescription from 'components/HeaderDescription';

// types
import type { FeedbackNotSumbittedProps } from 'components/ThankYouPage/types';
import { Flow } from 'store/modules/config/types';

export const FeedbackNotSubmitted: VFC<FeedbackNotSumbittedProps> = function ({
  claimNumber,
  comment,
  isFeedbackLoading,
  rating,
  setComment,
  setRating,
  submitRating,
  translation,
  type,
}) {
  // redux
  const links = useAppSelector(selectLinks);

  switch (type) {
    case Flow.recharge:
    case Flow.billing:
      return (
        <HeaderDescription title={translation.header.title.notSubmitted}>
          <Typography className="text-center" my={3} px={{ xs: 3, sm: 5 }}>
            {translation.description}
            <Link
              underline="always"
              href={translation?.links?.helpEmail || links.helpEmail}
              target="_blank"
            >
              {translation.link}
            </Link>
            {translation.footer}
          </Typography>
        </HeaderDescription>
      );

    case Flow.cancellation:
      return (
        <HeaderDescription title={translation.header.title.notSubmitted}>
          <>
            <Typography className="text-center" my={3}>
              {translation.header.description.row1}
              {translation.header.description.row2}
              {translation.header.description.row3} {claimNumber}
            </Typography>
            <Typography className="text-center" my={3} px={{ xs: 3, sm: 5 }}>
              {translation.description}
              <Link
                underline="always"
                href={translation?.links?.helpEmail || links.helpEmail}
                target="_blank"
              >
                {translation.link}
              </Link>
              {translation.footer}
            </Typography>
          </>
        </HeaderDescription>
      );

    default:
      return (
        <>
          <HeaderDescription title={translation.header.title.notSubmitted}>
            <>
              <Typography className="text-center" my={3}>
                {translation.header.description.row1}
                {translation.header.description.row2}
                {translation.header.description.row3} {claimNumber}
              </Typography>

              <Typography className="text-center" my={3} px={{ xs: 3, sm: 5 }}>
                {translation.description}
                <Link
                  underline="always"
                  href={translation?.links?.helpEmail || links.helpEmail}
                  target="_blank"
                >
                  {translation.link}
                </Link>
                {translation.footer}
              </Typography>
            </>
          </HeaderDescription>
          <Footer
            translation={translation}
            rating={rating}
            setRating={setRating}
            setComment={setComment}
            comment={comment}
            isFeedbackLoading={isFeedbackLoading}
            submitRating={submitRating}
          />
        </>
      );
  }
};
