// react
import React, { useCallback, useState, VFC } from 'react';

// redux
import {
  selectClaimNumber,
  selectConfirmationVoidNumber,
} from 'store/modules/claims/selectors';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { clearState as authClearState } from 'store/modules/auth/slice';
import { clearState as claimsClearState } from 'store/modules/claims/slice';
import { clearState as customerClearState } from 'store/modules/customer/slice';
import { clearState as ordersClearState } from 'store/modules/orders/slice';
import { clearState as perilsClearState } from 'store/modules/perils/slice';
import { createFeedback } from 'store/modules/thanks/thunks';
import {
  selectFeedbackLoading,
  selectFeedbackSubmitted,
} from 'store/modules/thanks/selectors';
import { clearThanksState } from 'store/modules/thanks/slice';
import { clearAllForms } from 'store/modules/forms';
import { Feedback } from 'store/modules/thanks/types';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';

// packages
import Stack from '@mui/material/Stack';

// components
import Loader from 'components/Loader';
import PrintButton from 'components/Print';
import { FeedbackNotSubmitted } from 'components/ThankYouPage/FeedbackNotSubmitted';
import { FeedbackSubmitted } from 'components/ThankYouPage/FeedbackSubmitted';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { remove } from 'utils/localstorage';
import { lsReduxKey } from 'store/browserStorage';

// types
import type { ThanksPageType } from 'utils/translation/types';
import type { ThankYouPageContentProps } from 'components/ThankYouPage/types';
import { Flow, FlowType } from 'store/modules/config/types';

export const characterLimit = 1000;

const ThankYouPage: React.FC = function () {
  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();

  // state
  const [rating, setRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');

  // redux
  const claimNumber = useAppSelector(selectClaimNumber);
  const isFeedbackLoading = useAppSelector(selectFeedbackLoading);
  const isFeedbackSubmitted = useAppSelector(selectFeedbackSubmitted);
  const productInUrl = useAppSelector(selectUrlProduct);
  const type: FlowType = useAppSelector(selectFlowType);
  const voidNumber = useAppSelector(selectConfirmationVoidNumber);

  window.onpopstate = function () {
    history.go(1);
  };

  const submitRating = () => {
    const data: Feedback = {
      source: 'Claims Form',
      claim: claimNumber || '',
      receive_updates: false,
      ...(comment && { comment }),
      ...(rating && { rating }),
      ...(rating && { rating }),
    };

    dispatch(createFeedback(data));
  };

  const clearState = useCallback(() => {
    remove(lsReduxKey).then((_) => {
      dispatch(claimsClearState());
      dispatch(ordersClearState());
      dispatch(perilsClearState());
      dispatch(customerClearState());
      dispatch(clearThanksState());
      dispatch(clearAllForms());
      dispatch(authClearState());
    });
  }, [dispatch]);

  const startOver = useCallback(() => {
    clearState();
    return history.push(`/${productInUrl}/${type}`);
  }, [clearState, history, productInUrl, type]);

  const translation = generateTranslation('thanksPage') as ThanksPageType;

  if (pageIsLoading(voidNumber, claimNumber, type)) return <Loader />;

  return (
    <>
      <div className="text-right">
        <PrintButton />
      </div>
      <ThankYouPageContent
        translation={translation}
        rating={rating}
        claimNumber={claimNumber || voidNumber || ''}
        comment={comment}
        isFeedbackLoading={isFeedbackLoading}
        isFeedbackSubmitted={isFeedbackSubmitted}
        type={type}
        startOver={startOver}
        setRating={setRating}
        setComment={setComment}
        submitRating={submitRating}
      />
    </>
  );
};

function pageIsLoading(
  voidNumber: string | undefined,
  claimNumber: string | undefined,
  type: FlowType
) {
  if (!claimNumber && !voidNumber)
    return type !== Flow.billing && type !== Flow.recharge;

  return false;
}

export default ThankYouPage;

const ThankYouPageContent: VFC<ThankYouPageContentProps> = function ({
  claimNumber,
  comment,
  isFeedbackLoading,
  isFeedbackSubmitted,
  rating,
  setComment,
  setRating,
  startOver,
  submitRating,
  translation,
  type,
}) {
  if (
    type === Flow.cancellation ||
    type === Flow.recharge ||
    type === Flow.billing
  )
    return (
      <Stack alignItems="center">
        <FeedbackNotSubmitted
          translation={translation}
          rating={rating}
          setRating={setRating}
          setComment={setComment}
          claimNumber={claimNumber}
          comment={comment}
          isFeedbackLoading={isFeedbackLoading}
          submitRating={submitRating}
          type={type}
        />
      </Stack>
    );

  return (
    <Stack alignItems="center">
      {isFeedbackSubmitted ? (
        <FeedbackSubmitted translation={translation} startOver={startOver} />
      ) : (
        <FeedbackNotSubmitted
          translation={translation}
          rating={rating}
          setRating={setRating}
          setComment={setComment}
          claimNumber={claimNumber}
          comment={comment}
          isFeedbackLoading={isFeedbackLoading}
          submitRating={submitRating}
          type={type}
        />
      )}
    </Stack>
  );
};
