// react
import React, { VFC } from 'react';

// packages
import Typography from '@mui/material/Typography';

// redux
import {
  selectSelectedItems,
  selectSelectedOrder,
} from 'store/modules/orders/selectors';
import { selectFlowType } from 'store/modules/config/selectors';

// hooks
import { UseFormReturn } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';

// components
import CreditCardForm from 'components/FormBuilder/components/CreditCardPanel/CreditCardForm';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import ReviewTable from 'components/ReviewPage/ReviewTable';

// types
import { Flow } from 'store/modules/config/types';

interface ICreditCardPanel {
  description: string;
  forceShowReviewTable?: boolean;
  formApi: UseFormReturn<any, any>;
  name: string;
}

export const CreditCardPanel: VFC<ICreditCardPanel> = function ({
  description,
  forceShowReviewTable = false,
  formApi: { resetField, setValue, control, watch },
  name,
}) {
  // redux
  const type = useAppSelector(selectFlowType);
  const selectedItems = useAppSelector(selectSelectedItems);
  const selectedOrder = useAppSelector(selectSelectedOrder);

  return (
    <>
      {(type === Flow.recharge || forceShowReviewTable) &&
        selectedItems &&
        selectedOrder && (
          <ReviewTable
            selectedItems={selectedItems}
            selectedOrder={selectedOrder}
            displayPremiumTotal
          />
        )}
      <Panel>
        <Layout>
          <Typography mb={2}>{description}</Typography>
          <CreditCardForm
            control={control}
            name={name}
            watch={watch}
            resetField={resetField}
            selectedOrderAddress={selectedOrder?.customer}
            setValue={setValue}
          />
        </Layout>
      </Panel>
    </>
  );
};
