// react
import React from 'react';

// packages
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { store } from 'store';
import { Provider } from 'react-redux';

// redux
import { setupInterceptors } from 'store/baseApi';

// components
import AppInitializer from 'AppInitializer';

// utils
import { getFlowTypeAndProduct, getUrlDomains } from 'utils/helpers';

import pkg from '../package.json';
import * as serviceWorker from './serviceWorker';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'user-forms',
  env: process.env.REACT_APP_NODE_ENV,
  version: pkg.version,
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();

// To start mock add an env var "MOCK" and set to true.
if (process.env.REACT_APP_MOCK && process.env.REACT_APP_MOCK === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

const { client } = getUrlDomains();
const { product: productInUrl, type } = getFlowTypeAndProduct(
  window.location.pathname
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AppInitializer product={productInUrl} client={client} type={type} />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

setupInterceptors(store);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
