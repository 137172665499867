// react
import React, { FC, useEffect, useState } from 'react';

// packages
import Stack from '@mui/material/Stack';

// redux
import {
  selectFlowType,
  selectSteps,
  selectUrlProduct,
} from 'store/modules/config/selectors';

// hooks
import { useAppSelector } from 'store/hooks';
import { useLocation } from 'react-router-dom';

// components
import BackArrow from 'components/BackArrow';
import Stepper from 'components/Stepper';

// utils
import { generateProgressBarSteps } from 'utils/progressBar';
import { stepName } from 'globalVariables';

// types
export type ProgressBarStepProps = {
  text: string;
  stateName: string;
  to?: string;
  backText?: string;
};

const ProgressBar = function () {
  // redux
  const location = useLocation();
  const productInUrl = useAppSelector(selectUrlProduct);
  const routes = useAppSelector(selectSteps);
  const type = useAppSelector(selectFlowType);

  // state
  const [state, setState] = useState<{
    currentStep: ProgressBarStepProps | null;
    activeIndex: number;
  }>({
    currentStep: null,
    activeIndex: 0,
  });
  const [steps] = useState<ProgressBarStepProps[]>([
    ...generateProgressBarSteps(routes, stepName, type, productInUrl!),
  ]);

  // constants
  const hideStepper =
    routes.some((route) => Boolean(route.conditional)) || steps.length === 1;

  useEffect(() => {
    const match = steps.some((step, index) => {
      if (step.stateName === location.pathname) {
        setState({
          currentStep: step,
          activeIndex: index,
        });
        return true;
      }

      return false;
    });

    if (match) return;

    setState({
      currentStep: null,
      activeIndex: 0,
    });
  }, [location.pathname, steps]);

  if (!state.currentStep || state.currentStep.text === 'ThankYouPage')
    return null;

  return (
    <>
      <BackIcon currentStep={state.currentStep} />
      {!hideStepper && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          <Stepper currentStep={state.activeIndex} steps={steps} />
        </Stack>
      )}
    </>
  );
};

const BackIcon: FC<{
  currentStep: ProgressBarStepProps;
}> = function ({ currentStep }) {
  if (!currentStep.to) return null;

  return <BackArrow to={currentStep.to ?? '/'} text={currentStep.backText} />;
};

export default ProgressBar;
