// react
import React from 'react';

// packages
import Box from '@mui/material/Box';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';

const PrintButton = function () {
  // functions
  const click = () => {
    window.print();
  };

  return (
    <Box
      component="span"
      onClick={click}
      onKeyDown={(event) => {
        if (event.key.toLowerCase() !== 'enter') return;

        click();
      }}
      role="button"
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      <Box pt={2}>
        <PrintRoundedIcon style={{ verticalAlign: 'middle' }} /> Print
      </Box>
    </Box>
  );
};

export default PrintButton;
