// react
import React, { VFC } from 'react';

// packages
import Cards from 'react-credit-cards';
import Stack from '@mui/material/Stack';

// styles
import 'react-credit-cards/es/styles-compiled.css';

// icons
import amex from 'assets/card-american-express.svg';
import discover from 'assets/card-discover.svg';
import mc from 'assets/card-mc_vrt_pos.svg';
import ssl from 'assets/card-SSLSecure_icon.svg';
import visa from 'assets/card-Visa_Inc._logo.svg';

const cardImages = [ssl, mc, amex, discover, visa];

const CreditCard: VFC<{
  cvc: string;
  expiry: string;
  focused?: string;
  name: string;
  number: string;
}> = function ({ cvc, expiry, name, number, focused }) {
  return (
    <>
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focused}
        name={name}
        number={number}
      />
      <Stack
        direction="row"
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
        justifyContent="center"
        spacing={2}
      >
        {cardImages.map((img, i) => (
          <img
            alt={i === 0 ? 'ssl secure' : 'card type'}
            key={i}
            src={img}
            style={{
              maxWidth: i === 0 ? '100px' : '50px',
              ...(i === 0 && { transform: 'scale(1.5)' }),
            }}
          />
        ))}
      </Stack>
    </>
  );
};

export default CreditCard;
