// react
import React, { VFC } from 'react';

// packages
import { Redirect, Switch, Route } from 'react-router-dom';

// redux
import {
  selectFlowType,
  selectSteps,
  selectUrlProduct,
} from 'store/modules/config/selectors';

// hooks
import { useAppSelector } from 'store/hooks';

// components
import CancellationErrorPage from 'components/CancellationErrorPage';
import EmailSent from 'components/EmailSent';
import FilePage from 'components/FilePage';
import FourOFourPage from 'components/404Page';
import LandingPage from 'components/LandingPage';
import Orders from 'components/OrdersPage';
import Payment from 'components/Payment';
import PaymentFailure from 'components/PaymentFailure';
import PaymentSuccess from 'components/PaymentSuccess';
import ScrollToTop from 'components/ScrollToTop';
import ValidatedFormBuilder from 'ValidatedFormBuilder';

// utils
import { stepName } from 'globalVariables';

// styles
import 'styles/app.css';

export const style = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    maxWidth: '100%',
  },
  mainContainer: {
    flex: '1 1 100%',
  },
} as const;

const App: VFC = function () {
  // redux state
  const productInUrl = useAppSelector(selectUrlProduct);
  const routes = useAppSelector(selectSteps);
  const type = useAppSelector(selectFlowType);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/404">
          <FourOFourPage />
        </Route>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path={`/${productInUrl}/${type}`}>
          <FilePage />
        </Route>
        <Route exact path={`/${productInUrl}/${type}/email-sent`}>
          <EmailSent />
        </Route>
        <Route exact path="/payment">
          <Payment />
        </Route>
        <Route exact path="/payment-failure">
          <PaymentFailure />
        </Route>
        <Route exact path="/payment-success">
          <PaymentSuccess />
        </Route>
        <Route exact path={`/${productInUrl}/${type}/orders`}>
          <Orders />
        </Route>
        {routes.map((step, index) => {
          const stepId = index + 1;
          return (
            <Route
              key={`route${index}`}
              path={`/${productInUrl}/${type}/${stepName}${stepId}`}
              exact
            >
              <ValidatedFormBuilder
                layout={step?.fields}
                stepId={stepId}
                lastStepIndex={routes.length}
              />
            </Route>
          );
        })}
        <Route path={`/${productInUrl}/cancellation/error`}>
          <CancellationErrorPage />
        </Route>
        <Redirect from="*" to={`/${productInUrl}/${type}`} />
      </Switch>
    </>
  );
};

export default App;
