// react
import { useEffect, VFC } from 'react';

// packages
import { useLocation } from 'react-router-dom';

const ScrollToTop: VFC = function () {
  // hooks
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
