// react
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  VFC,
} from 'react';

// packages
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// redux
import { selectAlert } from 'store/modules/alert/selectors';
import { pushMessageAlert } from 'store/modules/alert/slice';
import { clearState as clearAuthState } from 'store/modules/auth/slice';
import {
  selectAccessToken,
  selectAccessTokenStatus,
} from 'store/modules/auth/selectors';
import {
  selectInterruptedSession,
  selectReviewComplete,
} from 'store/modules/claims/selectors';
import {
  setInterruptionValue,
  setPreferredLang,
} from 'store/modules/claims/slice';
import {
  selectFlowType,
  selectIsConfigStatusError,
  selectNumberOfSteps,
  selectStartOver,
  selectTranslationOverride,
  selectUrlProduct,
} from 'store/modules/config/selectors';

import { sessionExpiredError } from 'store/errorInterceptor';
import {
  getLastUncompletedStep,
  selectAllStepsComplete,
} from 'store/modules/forms';

// hooks
import useMutationObserver from '@rooks/use-mutation-observer';
import { useHistory, useLocation } from 'react-router-dom';
import { lsReduxKey } from 'store/browserStorage';
import { useBoolean } from 'react-use';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useClearReduxPersistedState } from 'hooks/useClearReduxPersistedState';
import { useInterval } from 'hooks/useInterval';
import { useExcludedRoute } from 'hooks';

// utils
import { deepMerge, isEmptyObject } from 'utils/object';
import { get, has } from 'utils/localstorage';
import { getProductValue } from 'store/utils/hooks';
import translations from 'translations';
import { extendAccessToken } from 'store/modules/auth/thunk';
import { stepName } from 'globalVariables';
import i18next from 'i18next';
import { loadProductTranslations } from 'utils/translation';

// components
import AlertBar from 'components/AlertBar';
import Footer from 'components/Footer/V1';
import Header from 'components/Header/V1';
import Loader from 'components/Loader';
import ProgressBar from 'components/ProgressBar';
import SessionModal from 'components/SessionModal';
import { GoogleTranslator } from 'components/GoogleTranslator';

// styles
import 'styles/app.css';

// types
import { Flow } from 'store/modules/config/types';
import { ProductEnum } from 'types';

export const style = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    maxWidth: '100%',
  },
  mainContainer: {
    flex: '1 1 100%',
  },
} as const;

const languageObserverConfig = { attributes: true };

const V1Layout: VFC<{ children: ReactNode; forceShowLayoutItems?: boolean }> =
  function ({ children, forceShowLayoutItems = false }) {
    // redux state
    const accessToken = useAppSelector(selectAccessToken);
    const accessTokenStatus = useAppSelector(selectAccessTokenStatus);
    const alert = useAppSelector(selectAlert);
    const allStepsAreComplete = useAppSelector(selectAllStepsComplete);
    const configErrorStatus = useAppSelector(selectIsConfigStatusError);
    const interruptedSession = useAppSelector(selectInterruptedSession);
    const lastStepIndex = useAppSelector(selectNumberOfSteps);
    const lastUncompletedStep = useAppSelector(getLastUncompletedStep);
    const productInUrl = useAppSelector(selectUrlProduct);
    const productInApi = getProductValue(productInUrl);
    const reviewIsComplete = useAppSelector(selectReviewComplete);
    const startOver = useAppSelector(selectStartOver);
    const translationOverride = useAppSelector(selectTranslationOverride);
    const type = useAppSelector(selectFlowType);

    // hooks
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useBoolean(true);
    const [showSessionModal, setShowSessionModal] = useBoolean(false);
    const { clearReduxPersistedStateAndRedirect } =
      useClearReduxPersistedState();
    const excludedRoute = useExcludedRoute();
    const history = useHistory();
    const location = useLocation();
    const { pathname } = useLocation();
    const rootDomToWatch = useRef(document.documentElement);

    // state
    const [language, setLanguage] = useState(document.documentElement.lang);
    const [displayLocation, setDisplayLocation] = useState<string>(
      location.pathname
    );
    const [transitionStage, setTransistionStage] = useState<
      'fadeIn' | 'fadeOut'
    >('fadeIn');

    // constants
    const showLayoutItems = !configErrorStatus || forceShowLayoutItems;

    useEffect(() => {
      if (location.pathname !== displayLocation) setTransistionStage('fadeOut');
    }, [location.pathname, displayLocation]);

    const restoreSession = useCallback(() => {
      // Send them to final step, router will move them
      // to the appropriate location
      dispatch(setInterruptionValue(false));
      const lastUncompletedStepName = `/${productInUrl}/${type}${lastUncompletedStep}`;
      const lastStepName = `/${productInUrl}/${type}/${stepName}${lastStepIndex}`;

      if (reviewIsComplete && pathname === lastStepName)
        return history.push(`/${productInUrl}/${type}`);

      return isSamePath(pathname, lastUncompletedStepName)
        ? setShowSessionModal(false)
        : history.push(lastUncompletedStepName);
    }, [
      dispatch,
      pathname,
      productInUrl,
      type,
      lastUncompletedStep,
      lastStepIndex,
      reviewIsComplete,
      history,
      setShowSessionModal,
    ]);

    useInterval(
      () => {
        if (!accessToken) return;

        dispatch(extendAccessToken({ accessToken }));
      },
      // Delay in milliseconds or null to stop it
      accessToken ? 30 * 60 * 1000 : null
    );

    useEffect(() => {
      if (excludedRoute || accessTokenStatus !== 'error') return;

      localStorage.removeItem('token');

      dispatch(clearAuthState());

      history.push(`/${productInUrl}/${type}`);
    }, [
      accessTokenStatus,
      excludedRoute,
      history,
      type,
      productInUrl,
      dispatch,
    ]);

    useEffect(() => {
      window.addEventListener('beforeunload', () => {
        if (!interruptedSession) {
          dispatch(setInterruptionValue(true));
        }
      });
      return () =>
        window.removeEventListener('beforeunload', () => {
          if (!interruptedSession) {
            dispatch(setInterruptionValue(true));
          }
        });
    }, [dispatch, interruptedSession]);

    const languageMutationCb = (mutationsList: MutationRecord[]) => {
      let languageInHtml = document.documentElement.lang;

      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          languageInHtml = document.documentElement.lang;

          if (language !== languageInHtml) {
            setLanguage(languageInHtml);
            dispatch(setPreferredLang(languageInHtml));
          }
        }
      }
    };

    const clearErrorMessage = () => {
      dispatch(
        pushMessageAlert({
          message: '',
          type: undefined,
        })
      );
    };

    useEffect(() => {
      dispatch(setPreferredLang(document.documentElement.lang));
    }, [dispatch]);

    const handleLoadProductTranslations = useCallback(
      ({ product = ProductEnum.fanshield, type }) => {
        try {
          const prodTranslations = loadProductTranslations(product, type);

          i18next
            .init({
              lng: 'en',
              resources: {
                en: {
                  translation: {
                    product: {
                      ...deepMerge(
                        prodTranslations,
                        translationOverride.product
                      ),
                    },
                    general: {
                      ...deepMerge(
                        translations[type].general,
                        translationOverride.general
                      ),
                    },
                  },
                },
              },
            })
            .then(() => {
              setLoading(false);
            });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
      [setLoading, translationOverride.general, translationOverride.product]
    );

    useEffect(() => {
      if (!loading) return;

      handleLoadProductTranslations({
        product: productInApi || ProductEnum.fanshield,
        // if there's no product in the URL, we are on the EPX payment flow and should display the "recharge" verbiage
        type: productInApi ? type : 'recharge',
      });
    }, [handleLoadProductTranslations, loading, productInApi, type]);

    // Watches the root dom element lang attribute that is set by google translate. When it changes, we update redux language for claim.
    useMutationObserver(
      rootDomToWatch,
      languageMutationCb,
      languageObserverConfig
    );

    useEffect(() => {
      const visibility =
        interruptedSession && (!allStepsAreComplete || !reviewIsComplete);

      if (!has(lsReduxKey))
        return setShowSessionModal(visibility && !startOver);

      const store = get(lsReduxKey);

      try {
        const parsed = JSON.parse(store ?? '');
        const emptyForm = isEmptyObject(parsed.formsReducer?.forms);
        const orderPassed = parsed.ordersReducer?.fileUploadConsent;

        return setShowSessionModal(
          (!emptyForm || orderPassed) && visibility && !startOver
        );
      } catch (e) {
        return setShowSessionModal(visibility && !startOver);
      }
    }, [
      allStepsAreComplete,
      interruptedSession,
      reviewIsComplete,
      startOver,
      setShowSessionModal,
      productInUrl,
      type,
      lastUncompletedStep,
      pathname,
    ]);

    useEffect(() => {
      if (history.location.pathname === '/') return;

      if (alert.message === sessionExpiredError)
        clearReduxPersistedStateAndRedirect();
    }, [
      alert.message,
      clearReduxPersistedStateAndRedirect,
      history.location.pathname,
    ]);

    if (loading && !configErrorStatus && !excludedRoute) return <Loader />;

    const commonContainerStyles = {
      maxWidth: {
        xs:
          type === Flow.cancellation || type === Flow.certificate
            ? '1200px'
            : '1000px',
      },
      padding: { md: 4, sm: 3, xs: '5px' },
    };

    return (
      <div style={style.app}>
        <Box sx={{ ...commonContainerStyles, margin: '0 auto', width: '100%' }}>
          <GoogleTranslator />
        </Box>
        <main style={style.mainContainer}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <AlertBar
              type={alert.type || 'error'}
              message={alert.message}
              excludedRoutes={['/']}
              dismissAlertCb={clearErrorMessage}
            />

            {showLayoutItems && <Header />}
            <Container sx={commonContainerStyles}>
              <div
                className={`${transitionStage}`}
                onAnimationEnd={() => {
                  if (transitionStage === 'fadeOut') {
                    setTransistionStage('fadeIn');
                    setDisplayLocation(location.pathname);
                  }
                }}
              >
                <ProgressBar />
                {children}
                <SessionModal
                  show={showSessionModal}
                  onSubmit={restoreSession}
                  onDecline={clearReduxPersistedStateAndRedirect}
                />
              </div>
            </Container>
          </LocalizationProvider>
        </main>
        {showLayoutItems && <Footer />}
      </div>
    );
  };

export default V1Layout;

const isSamePath = (pathname: string, lastStepName: string) =>
  pathname === lastStepName || `${pathname}/` === lastStepName;
